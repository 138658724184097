<template>
  <div class="home">
    <div class="top flex">
      <img class="logo" src="@/assets/home2/logo@2x.png" alt="">
      <div class="flex">
        <div class="nav" @click="goId('#about')">关于赛事</div>
        <div class="nav" @click="goId('#format')">赛事赛制</div>
        <div class="nav" @click="goId('#review')">评审介绍</div>
        <div class="nav" @click="goSign">赛事报名</div>
      </div>
    </div>
    <img class="banner" src="@/assets/home2/banner@2x.png" alt="">
    <!-- 关于赛事 -->
    <div id="about" class="part2">
      <span></span>
      <div class="gyss">关于赛事</div>
      <!-- <img class="gyss" src="@/assets/home1/gyss@2x.png" alt=""> -->
      <img class="jx" src="@/assets/home2/jx@2x.png" alt="">
      <img class="kv" src="@/assets/home2/KV@2x.png" alt="">
      <img class="about" src="@/assets/home1/about@2x.png" alt="">
      <img class="wycs" src="@/assets/home1/wycs01@2x.png" alt="">
      <div style="cursor: pointer" @click="goAboutss" class="ssmore">了解详情 &gt;</div>
      <img class="bcss" src="@/assets/home1/bcss@2x.png" alt="">
      <div class="piclist flex">
        <img class="ss" src="@/assets/home2/ss01@2x.png" alt="">
        <img class="ss" src="@/assets/home2/ss02@2x.png" alt="">
        <img class="ss" src="@/assets/home2/ss03@2x.png" alt="">
        <img class="ss" src="@/assets/home2/ss04@2x.png" alt="">
      </div>
    </div>
    <!-- 赛事赛制 -->
    <div id="format" class="part3">
      <span></span>
      <div class="sssz">赛事赛制</div>
      <img class="banner02" src="@/assets/home2/banner02@2x.png" alt="">
      <div class="piclist02 flex-between">
        <div @click="activecard=index" :class="[activecard==index?'activecard':'card']" v-for="(item,index) in cardList" :key="index">
            <img v-if="activecard==index" :src="item.active" alt="">
            <img  v-else :src="item.src" alt="">
            <div class="text1">{{item.title}}</div>
            <div class="text2">{{item.info}}</div>
        </div>
      </div>
      <img v-if="activecard== 0" class="picinfo" src='@/assets/home2/info@2x.png' alt="">
      <img v-if="activecard== 1" class="picinfo" src='@/assets/home2/info02@2x.png' alt="">
      <img v-if="activecard== 2" class="picinfo" src='@/assets/home2/info03@2x.png' alt="">
      <img v-if="activecard== 3" class="picinfo" src='@/assets/home2/info04@2x.png' alt="">
      <img v-if="activecard== 4" class="picinfo" src='@/assets/home2/info05@2x.png' alt="">
      <div style="cursor: pointer" @click="goSaidao" class="more">了解详情 ></div>
    </div>
    <!-- 评审介绍 -->
    <div id="review" class="part4">
      <span></span>
      <div class="psjs">评审介绍</div>
      <div class="card-wrap">
        <div class="card" v-for="(item,index) in peopleList"  :key="index">
          <img :src="item.src" alt="">
          <div class="name">{{item.name}}</div>
          <div class="position">{{item.position}}</div>
          <div class="info" v-for="(iitem,iindex) in item.info" :key="iindex">{{iitem}}</div>
        </div>
      </div>


    </div>
    <!-- footer -->
    <div id="sign" class="footer">
      <img class="logobottom" src="@/assets/home1/logobottom@2x.png" alt="">
      <div class="text-wrap flex-vcbetween">
        <div>
          <div><span>指导单位</span>中共常熟市委宣传部</div>
          <div><span>主办单位</span>常熟文旅发展有限责任公司</div>
          <div><span>承办单位</span>吉捷文化传媒（上海）有限公司</div>
        </div>
        <div class="flex-vc">
          <div><span>官方热线:</span></div>
          <div>
            <div>邮箱: 962991363@qq.com</div>
            <div>微信: 13817518262</div>
          </div>
        </div>
      </div>
      <div class="text-bottom flex-between">
        <div class="fs13"><img class="c" src="@/assets/home1/C@2x.png" alt="">2021 <span style="font-weight: bold">changshustory.</span> ALL Right Reserved <a style="color:#666" href="https://beian.miit.gov.cn/">沪ICP备12038716号-10</a></div>
        <div class="fs15"><span>关于赛事</span>|<span>赛事赛制</span>|<span style="margin-right:0">评审介绍</span></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  data() {
    return {
      cardList: [
        {
          src: require('@/assets/home1/img01@2x.png'),
          active: require('@/assets/home2/ip@2x.png'),
          title: '赛道一' ,
          info: '常熟文创形象“虞小主”卡通形象设计'
        },
        {
          src: require('@/assets/home1/img02@2x.png'),
          active: require('@/assets/home2/ip2@2x.png'),
          title: '赛道二' ,
          info: '常熟大米产品包装设计'
        },
        {
          src: require('@/assets/home1/img03@2x.png'),
          active: require('@/assets/home2/ip3@2x.png'),
          title: '赛道三' ,
          info: '虞山碧螺春包装设计'
        },
        {
          active: require('@/assets/home2/ip4@2x.png'),
          src: require('@/assets/home1/img04@2x.png'),
          title: '赛道四' ,
          info: '常熟文化主题生活用品设计'
        },

        {
          src: require('@/assets/home1/img05@2x.png'),
          active: require('@/assets/home2/ip5@2x.png'),
          title: '赛道五' ,
          info: '“无界常熟”“文创物品” 创新设计开放命题'
        },
      ],
      peopleList: [
        {
          src: require('@/assets/home2/peo01@2x.png'),
          name: '祁 林',
          position: '(赛事顾问)',
          info: ['南京大学艺术学院教授、' ,'南京大学出版社副主编、' ,'教育部创新创业教指委委员', '中国高等院校影视学会理事、', '中国高等院校影视学会网络视听协会副主任委员']
        },
        {
          src: require('@/assets/home2/peo02@2x.png'),
          name: '李守白',
          position: '(赛事评审)',
          info: ['上海市文联副主席、' ,'上海民协主席' ,' 非遗“海派剪纸”代表性传承人、']
        },        
        {
          src: require('@/assets/home2/peo03@2x.png'),
          name: '张黎明',
          position: '(赛事评审)',
          info: ['上海市非物质文化遗产保护中心办公室主任' ,'上海市非物质文化遗产专家委员会委员']
        },
        {
          src: require('@/assets/home2/peo04@2x.png'),
          name: '邵 刚',
          position: '(赛事评审)',
          info: ['茶产业赋能平台【茶能社】' ,'文创品牌【绎匠研创】创始人 ','国家高级评茶师']
        },
        {
          src: require('@/assets/home2/peo05@2x.png'),
          name: '姚 诞',
          position: '(赛事评审)',
          info: ['原上海工艺美术职业学院' ,'工艺美术研究中心主任 ']
        },
        {
          src: require('@/assets/home2/peo06@2x.png'),
          name: '大众评委',
          position: '',
          info: ['将与赛事评审一同进行评选工作' ,'评审阶段于线上进行投票 ']
        },
      ],
      activecard: 0,
      id: this.$route.query.id || ''
    }
  },
  methods: {
    goSign() {
      this.$router.push('/sign')
    },
    goId(id) {
      const returnEle = document.querySelector(id);  //id是将要跳转区域的id
        if (!!returnEle) {
          returnEle.scrollIntoView(true); // true 是默认的
        }
        document.querySelector(id).scrollIntoView(true)
    },
    goAboutss() {
      this.$router.push('/aboutss')
    },
    goSaidao() {
      switch(this.activecard) {
        case 0 :
          this.$router.push('/saidao1');
          break;
        case 1 :
          this.$router.push('/saidao2');
          break;
        case 2 :
          this.$router.push('/saidao3');
          break;
        case 3 :
          this.$router.push('/saidao4');
          break;
        case 4 :
          this.$router.push('/saidao5');
          break;
        default:
          break;
      }
    }
  },
  mounted() {
    if(this.id) {
      this.goId(this.id)
    }
  },

}
</script>
<style lang="less" scoped>
.flex {
  display: flex;
  align-items: center;
}
.flex-vc {
  display: flex;
  justify-content: center;
}
.flex-vcbetween {
  display: flex;
  // align-items: center;
  justify-content: space-between;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home {
  margin: 0 auto;
  width: 1920px;
  .top {
    height: 134px;
    .logo {
      width: 200px;
      margin-left: 360px;
      margin-right: 328.5px;
      cursor: pointer;
    }
    .nav:hover {
      color:#45B79C;
      font-weight: bold;
      cursor: pointer
    }
    .nav {
      width: 175px;
      font-size: 20px;
      position: relative;
      a {
        text-decoration: none;
        color: #333333;
      }
      span {
        position: absolute;
        left: 49.5px;
        top: 15px;
        display: block;
        width: 76px;
        height: 10px;
        background: #AAAAAA;
        z-index: -1;
      }
    }
  }
  .banner {
    width: 1920px;
    display: block;
  }
  .part2 {
    position: relative;
    height: 1165px;

    .gyss {
      // width: 682px;
      padding: 56px 0 68px 0;
      font-size: 48px;
      color: #23242A;
      line-height: 85px;
      font-weight: bold;
    }
    span {
        width: 204px;
        height: 26px;
        background: #B3E9DC;
        display: block;
        position: absolute;
        top: 98px;
        left: 858px;
        z-index: -1;
    }
    .jx {
      position: absolute;
      top: 209px;
      left: 673px;
      width: 446px;
    }
    .kv {
      width: 426px;
      position: absolute;
      top: 209px;
      left: 1119px;
    }
    .about {
      width: 420px;
      position: absolute;
      left: 375px;
      top: 284px;
    }
    .wycs {
      width: 372px;
      position: absolute;
      left: 375px;
      top: 344px;
    }
    .ssmore {
      background: url('~@/assets/home2/ssmore@2x.png');
      width: 120px;
      line-height: 46px;
      background-size: 100% 100%;
      position: absolute;
      left: 782px;
      top: 442px;
      font-size: 18px;
      color: #FFFFFF;
      padding-left: 40px;
    }
    .bcss {
      width: 567px;
      top: 567px;
      left: 526px;
      position: absolute;
    }
    .piclist {
      position: absolute;
      top: 813px;
      left: 375px;
      .ss {
        width: 280px;
        margin-right: 16.67px;
      }
    }
  }
  .part3 {
    position: relative;
    height: 1103px;
    .sssz {
      padding: 0 0 64px 0;
      font-size: 48px;
      color: #23242A;
      line-height: 85px;
      font-weight: bold;
    }
    span {
        width: 204px;
        height: 26px;
        background: #B3E9DC;
        display: block;
        position: absolute;
        top: 42px;
        left: 858px;
        z-index: -1;
    }
    .banner02 {
      height: 210px;
      display: block;
    }

    .piclist02 {
      width: 1170px;
      position: absolute;
      left: 352px;
      top: 222px;
      .activecard {
        width: 224px;
        height: 266px;
        background: #45B79C;
        border: 4px solid #FFFFFF;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.30);
        border-radius: 4px;
        img {
          width: 114px;
          margin: 27px auto 22px;
          display: block;
        }
        .text1 {
          font-size: 20px;
          color: #FFFFFF;
          font-weight: bold;
          margin-bottom: 5px;
        }
        .text2 {
          font-size: 16px;
          color: #FFFFFF;
          width: 166px;
          margin: 0 auto;
        }
      }
      .card {
        width: 224px;
        height: 266px;
        background: #FFFFFF;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.30);
        border-radius: 4px;
        img {
          width: 114px;
          margin: 27px auto 22px;
          display: block;
        }
        .text1 {
          font-size: 20px;
          color: #23242A;
          font-weight: bold;
          margin-bottom: 5px;
        }
        .text2 {
          font-size: 16px;
          color: #333333;
          width: 166px;
          margin: 0 auto;
        }
      }
    }
    .picinfo {
      top: 530px;
      left: 302px;
      position: absolute;
      // width: 1216px;
      width: 1264px;
    }
    .more {
      top: 938px;
      left: 1280px;
      position: absolute;
      background: url('~@/assets/home2/more@2x.png');
      width: 160px;
      height: 46px;
      line-height: 46px;
      padding-left: 50px;
      background-size: 100% 100%;
      font-size: 18px;
      color: #45B79C;
    }
  }
  .part4 {
    height: 1292px;
    position: relative;
    background: url('~@/assets/home2/bg@2x.png');
    // background: #F2F3F4;
    .psjs {
      padding-top: 54px;
      font-size: 48px;
      color: #23242A;
      font-weight: bold;
      position: relative;
      z-index: 1;
    }
    span {
        width: 204px;
        height: 26px;
        background: #B3E9DC;
        display: block;
        position: absolute;
        top: 96px;
        left: 858px;
        z-index: 0;
    }
    .card-wrap {
      width: 1230px;
      margin: 79px 270px 64px 360px;
      display: flex;
      flex-wrap: wrap;
      .card {
        width: 380px;
        height: 484px;
        background: #FFFFFF;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.30);
        border-radius: 8px;
        margin: 0 30px 30px 0;
        img {
          width: 200px;
          margin: 32px auto 3px;
          display: block;
        }
        .name {
          font-size: 38px;
          color: #23242A;
          font-weight: bold;
          line-height: 53px;
        }
        .position {
          font-size: 22px;
          color: #231815;
          line-height: 30px;
          margin: 2px auto 7px;
        }
        .info {
          font-size: 16px;
          color: #666666;
          line-height: 24px;
        }
      }
    }


  }
  .footer {
    width: 1920px;
    height: 170px;
    background: #C1C1C1;
    position: relative;
    .logobottom {
      position: absolute;
      top: 38px;
      left: 860px;
      width: 200px;
    }
    .text-wrap {
      width: 1170px;
      margin: 0 auto ;
      color: #666666;
      font-size: 13px;
      line-height: 32px;
      text-align: left;
      padding-top: 30px;
      span {
        font-weight: bold;
        font-size: 15px;
        // color: #FFFFFF;
        margin-right: 16px;
        display: inline-block;
      }
    }
    .text-bottom {
      width: 1170px;
      margin: 0 auto;
      line-height: 32px;
      .fs13 {
        font-size: 13px;
        color: #666;
        img {
          width: 10px;
        }
      }
      .fs15 {
        font-size: 15px;
        color: #ABACB1;
        span {
          color: #666666;
          margin: 0 26px;
          display: inline-block;
        }
      }
    }

  }
}
</style>


